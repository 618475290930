import "./XacNhanTaiKhoan.scss";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";
import {
    getAllTaiKhoan,
    updateTaiKhoan,
    getTaiKhoan,
} from "../redux/apiRequest";
const XacNhanTaiKhoan = () => {
    const { tenVietTat, idShop, idCtv, tenCtv, sdtCtv } = useParams();
    const taiKhoan = useSelector(
        (state) => state?.taiKhoan?.taiKhoan?.taiKhoan?.taiKhoan
    );
    const allTaiKhoan = useSelector(
        (state) => state?.taiKhoan?.taiKhoan?.allTaiKhoan?.allTaiKhoan
    );
    const [loading, setloading] = useState(0);
    const dispatch = useDispatch();
    useEffect(() => {
        getAllTaiKhoan(dispatch);
    }, []);
    console.log("allTaiKhoan", allTaiKhoan);
    console.log("taiKhoan", taiKhoan);
    console.log("loading", loading);
    const VND = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND",
    });
    const handleChiTietTk = (id) => {
        setloading(2);
        getTaiKhoan(id, dispatch);
        console.log("id", id);
    };
    return (
        <div className="view">
            <div className="mobile">
                {loading === 0 && (
                    <div className="XacNhanTaiKhoan-ConTaiNer">
                        <div className="quayLai-tieuDe">
                            <a
                                href={`/ca-nhan/${tenVietTat}/${idShop}/a/${idCtv}/${tenCtv}/${sdtCtv}`}
                                className="quayLai"
                            >
                                <i className="fa fa-angle-double-left"></i>Quay
                                Lại
                            </a>
                            <div className="tieuDe">Xác Nhận Tài Khoản</div>
                        </div>
                        <div className="allTaiKhoan">
                            <div className="ds">Danh Sách Tài Khoản</div>
                            {allTaiKhoan &&
                                allTaiKhoan.map((item, index) => {
                                    return (
                                        <div
                                            onClick={() =>
                                                handleChiTietTk(item?.user)
                                            }
                                            className="thongTinTK"
                                            key={index}
                                        >
                                            <div className="tenShop">
                                                {item?.ThongTinThem?.TenShop}
                                            </div>
                                            <div className="sdtShop">
                                                {item?.ThongTinThem?.sdtShop}
                                            </div>
                                            <div className="soTien">
                                                {VND.format(item?.NapTien)}
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                )}
                {loading === 2 && (
                    <div className="chiTietTk">
                        <div className="quayLai-tieuDe">
                            <div
                                onClick={() => setloading(0)}
                                className="quayLai"
                            >
                                <i className="fa fa-angle-double-left"></i>Quay
                                Lại
                            </div>
                            <div className="tieuDe">Chi Tiết Tài Khoản</div>
                        </div>
                        <div>
                            <div>
                                <div>ten</div>
                                <div>sdt</div>
                                <div>so tien</div>
                                <input placeholder="cong tien tk shop" />
                                <div>khuyen mai thêm</div>
                            </div>
                            <div>Xác Nhận</div>
                            <div>lich Su</div>
                        </div>
                    </div>
                )}
            </div>
            <div className="pc">
                <div className="XacNhanTaiKhoan-ConTaiNer">XacNhanTaiKhoan</div>
            </div>
        </div>
    );
};
export default XacNhanTaiKhoan;
